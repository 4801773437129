import { type VariantProps, cva } from 'class-variance-authority'
import { type SVGProps, forwardRef } from 'react'

import spriteHref from '#components/icons/generated/sprite.svg'
import { cn } from '#utils/utils'

import type { IconName } from '../icons/generated/icons'

export const iconStyles = cva('shrink-0 fill-current', {
  variants: {
    size: {
      xxs: 'size-2',
      xs: 'size-3',
      sm: 'size-3.5',
      md: 'size-4',
      lg: 'size-5',
      xl: 'size-6',
      '2xl': 'size-8',
      '3xl': 'size-10',
      '4xl': 'size-12',
      '5xl': 'size-16'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

export type IconStyleProps = VariantProps<typeof iconStyles>

type IconProps = SVGProps<SVGSVGElement> &
  IconStyleProps & {
    name: IconName
    className?: string
  }

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ name, size, className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        {...props}
        className={cn(iconStyles({ size }), className)}
        data-slot="icon"
      >
        <title>{name}</title>
        <use href={`${spriteHref}#${name}`} />
      </svg>
    )
  }
)

export type { IconName }
